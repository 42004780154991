<template>
  <div>
    <b-row>
      <b-col cols="4">
        <a
          :disabled="!period_id || loading"
          class="btn btn-primary font-weight-bolder font-size-sm mt-4 aligns"
          @click.prevent="download_report"
        >
          {{ $t('PAGES.REPORTS.DOWNLOAD_MUCF_1') }}
        </a>
        <b-form-select v-if="!loading" class="aligns mt-4 ml-4"
          v-model="period_id" :options="periodOptions">
        </b-form-select>
        <b-spinner v-if="loading" class="text-left" style="margin-left: 20px; margin-top: 16px;" label="Laddar..."></b-spinner>
      </b-col>

    </b-row>

  </div>
</template>



<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  mixins: [ toasts ],
  name: 'ExportUngReport1Button',
  components: {

  },
  props: [],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'initComplete'])
  },
  watch: {
    initComplete(newValue) {
      console.log('initComplete', this.initComplete);
      if (newValue) {
        this.init_page();
      }
    }
  },
  data() {
    return {
      periodOptions: [],
      period_id: null,
      loading: false
    };
  },
  mounted() {

    if (this.initComplete) {
      this.init_page();
    }

  },
  methods: {
    init_page() {
      for (const period of this.periods) {
        this.periodOptions.push({ text: period.name, value: period.id });
      }

      this.period_id = this.currentPeriodId;
    },
    download_report() {

      this.loading = true;

      axios
        .post(`/company/report/ung1`, {
          period_id: this.period_id
        })
        .then(res => {
          this.loading = false;

          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
            this.toastr('success', this.$t('COMMON.OK'), 'Rapport skapad');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel status');
          }
        })
        .catch(err => {
          this.loading = false;

          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa rapport');
        });
    },

  }
};
</script>
<style scoped>
  .aligns {
    float: left;
    max-width: 200px;
  }
</style>
